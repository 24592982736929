import {memo, ReactNode} from 'react';

export const OnboardingContainer = memo(({children}: {children: ReactNode}) => {
  return (
    <div className="min-h-screen max-w-[600px] mx-auto shadow-pageLayout bg-team-onboarding-background-gradient">
      {children}
    </div>
  );
});
OnboardingContainer.displayName = 'OnboardingContainer';
