import {Outlet} from 'react-router-dom';
import {ClassicPageContainer} from 'components/PageContainer/ClassicPageContainer';
import {FKOFAQSection} from 'components/FKO/FKOFAQSection/FKOFAQSection';

export default function FKOLayout() {
  return (
    <>
      <ClassicPageContainer>
        <Outlet />
      </ClassicPageContainer>
      <FKOFAQSection />
    </>
  );
}
