import {
  createAppointment,
  createMboClients,
  CreateMboClientsResult,
  createOrUpdateHubSpotContact,
  getCurrentUser,
  ProfileUpdate,
  saveErrorDetails,
  triggerBackendResync,
} from 'api/api';
import {
  DEFAULT_FKO_APPT_START_DAYS_FROM_TODAY,
  DEFAULT_FKO_BOOKING_WINDOW,
  DEFAULT_PLAYER_ASSESSMENT_APPT_START_DAYS_FROM_TODAY,
  DEFAULT_PLAYER_ASSESSMENT_BOOKING_WINDOW,
  getLocationBySiteId,
} from 'constants/locations';
import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import {FkoInitialParams, FkoPlayer, FkoPlayerWithSession} from 'redux/slices/fkoFormData';
import {URLSearchParams} from 'url';
import {Profile} from 'user/player-info.interface';
import {createRandomPassword, getCurrentAccountId} from 'user/user-utils';
import {currentDatePlusDays, isDateValid} from 'utils/utils';
import {logSignUpEvent} from './analytics-events';
import {auth as fireBaseAuth} from '../firebase/config';
import {ApiUser} from 'user/user.interface';
import {DateTime} from 'luxon';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY!;

export enum FreeTrialProgram {
  PICKUP_SOCCER = 'Adult Pickup Soccer',
  LEAGUES_TRIAL = 'Adult Soccer Leagues',
  FTC_DSR = 'TOCA Soccer Classes',
}

export const relevantRegistrationUrlParams = [
  'locationId',
  'trainingProgram',
  'fbclid',
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'embed',
  'date',
  'dateEnd',
  'dailyStartHour',
  'dailyStartMinute',
  'dailyEndHour',
  'dailyEndMinute',
  'teamId',
  'firstname',
  'lastname',
  'email',
  'phone',
];

export const relevantFTCDSRUrlParams = [
  'locations__drop_down_',
  'fbclid',
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

export const relevantFreePickupSoccerAndLeaguesUrlParams = [
  'locations__drop_down_',
  'i_am_interested_in___',
  'source',
  'fbclid',
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'firstname',
  'lastname',
  'email',
  'phone',
];

export const sendFkoHubspotData = ({
  alternateProfileData,
  currentProfile,
  initialParams,
  locationId,
  fkoMethod,
  isPlayerAssessment,
}: {
  alternateProfileData?: ProfileUpdate['update'];
  currentProfile?: Profile;
  initialParams?: FkoInitialParams;
  locationId?: string;
  fkoMethod: string;
  isPlayerAssessment: boolean;
}) => {
  try {
    const _locationId = locationId || initialParams?.locationId;
    const location = _locationId ? getLocationBySiteId(_locationId) : undefined;

    // hubspot cannot normally support certain playerAssessment-specific location names
    const hubspotLocationParameter = isPlayerAssessment
      ? {mytoca_site: location?.hubspotMyTocaSite ?? location?.hubspotName}
      : {locations__drop_down_: location?.hubspotName || location?.name || undefined};

    const hubspotContactInfo = {
      email: currentProfile?.email || alternateProfileData?.email,
      firstname: currentProfile?.firstName || alternateProfileData?.firstName,
      lastname: currentProfile?.lastName || alternateProfileData?.lastName,
      phone: currentProfile?.phoneNumber || alternateProfileData?.phoneNumber,
      fko_method: fkoMethod,
      fbclid: initialParams?.fbclid,
      gclid: initialParams?.gclid,
      utm_source: initialParams?.utm_source,
      utm_medium: initialParams?.utm_medium,
      utm_campaign: initialParams?.utm_campaign,
      utm_content: initialParams?.utm_content,
      utm_term: initialParams?.utm_term,
      hs_analytics_source: initialParams?.hs_analytics_source,
      ...hubspotLocationParameter,
    };

    createOrUpdateHubSpotContact(hubspotContactInfo).catch((err) => {
      console.error('Failed to create/update HubSpot contact', err);
    });
  } catch (err) {
    console.error('Failed to create/update HubSpot contact', err);
  }
};

type LocationDatesParams = {
  siteId?: string | null;
  dateOverride?: string | null;
  dateOverrideEnd?: string | null;
  isPlayerAssessment?: boolean;
};
// this determines the correct fko booking window, parsing defaults, location-specific settings, and manual overrides
export function getLocationSpecificFkoBookingDates({
  siteId,
  dateOverride,
  dateOverrideEnd,
  isPlayerAssessment,
}: LocationDatesParams) {
  // TODBAT locations support different timings toca/dbat?
  const location = siteId ? getLocationBySiteId(siteId) : undefined;

  try {
    if (location) {
      const appointmentsStartDaysFromToday = isPlayerAssessment
        ? DEFAULT_PLAYER_ASSESSMENT_APPT_START_DAYS_FROM_TODAY
        : location?.fkoAppointmentsStartDaysFromToday ?? DEFAULT_FKO_APPT_START_DAYS_FROM_TODAY;

      let appointmentsBookingWindowInDays = isPlayerAssessment
        ? DEFAULT_PLAYER_ASSESSMENT_BOOKING_WINDOW
        : location?.fkoAppointmentsBookingWindowInDays ?? DEFAULT_FKO_BOOKING_WINDOW;

      let startDate;
      let endDate;

      if (dateOverride && isDateValid(dateOverride)) {
        startDate = new Date(dateOverride.replace(/-/g, '/'));
        if (dateOverrideEnd) {
          endDate = isDateValid(dateOverrideEnd) ? new Date(dateOverrideEnd.replace(/-/g, '/')) : new Date(startDate);
          appointmentsBookingWindowInDays = DateTime.fromJSDate(endDate).diff(
            DateTime.fromJSDate(startDate),
            'days'
          ).days;
        } else {
          endDate = DateTime.fromJSDate(startDate).plus({days: appointmentsBookingWindowInDays}).toJSDate();
          appointmentsBookingWindowInDays = 0;
        }
      } else {
        startDate = currentDatePlusDays(appointmentsStartDaysFromToday);
        endDate = DateTime.fromJSDate(startDate).plus({days: appointmentsBookingWindowInDays}).toJSDate();
      }

      return {
        bookingWindowInDays: appointmentsBookingWindowInDays,
        startDateStr: startDate.toISOString().split('T')[0] + 'T00:00:00Z',
        endDateStr: endDate.toISOString().split('T')[0] + 'T23:59:59Z',
      };
    } else {
      return undefined;
    }
  } catch {
    console.error('error setting fko dates');
  }
}

export const parseFkoParamsFromSearchParams = (searchParams: URLSearchParams) => {
  const urlParams: any = {};

  for (const urlParamName of relevantRegistrationUrlParams) {
    const value = searchParams.get(urlParamName);
    if (value) {
      urlParams[urlParamName] = value;
    }
  }

  const sessionTypes = searchParams.getAll('sessionTypeNames');
  if (sessionTypes.length) {
    urlParams.sessionTypeNames = sessionTypes;
  }

  return urlParams;
};

export const parseRelevantParamsFromSearchParams = (searchParams: URLSearchParams, relevantUrlParams: string[]) => {
  const urlParams: any = {};
  for (const urlParamName of relevantUrlParams) {
    const value = searchParams.get(urlParamName);
    if (value) {
      urlParams[urlParamName] = value;
    }
  }

  return urlParams;
};

export const createMboClientsForNewFkoPlayers = async ({
  email,
  siteId,
  playersArray,
}: {
  email: string;
  siteId: string;
  playersArray: FkoPlayer[];
}) => {
  const newClients = playersArray
    .filter((player) => player.eligibility?.isEligible && !player.mboDetails?.clientId)
    .map((player) => ({
      clientSideOnlyId: player.playerId,
      accountHolder: player.accountHolder,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      firstName: player.firstName!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      lastName: player.lastName!,
      birthdate: player.dob,
      referredBy: player.isDbat ? 'FKO Web - DBAT' : 'FKO Web', // TODBAT confirm
      club: player.club,
    }));

  if (!newClients.length) {
    return new Promise<CreateMboClientsResult>((resolve) => resolve({results: []}));
  }

  return createMboClients({email, siteId, clients: newClients, isFko: true});
};

export const createFkoAppointments = async ({
  players,
  siteId,
  mboAppointmentNotes,
}: {
  players: FkoPlayerWithSession[];
  siteId: string;
  mboAppointmentNotes?: string;
}) => {
  if (!players.length) {
    return [];
  }

  const promises = [];
  for (const player of players) {
    promises.push(
      new Promise<{playerId: string; confirmed: boolean; response?: any; error?: string}>((resolve) => {
        const appointmentData = {
          ClientId: player.mboDetails.clientId,
          LocationId: player.selectedSession.location.id,
          SessionTypeId: player.selectedSession.sessionType.id,
          StaffId: player.selectedSession.staff.id,
          StaffRequested: true,
          SendEmail: true,
          StartDateTime: player.selectedSession.startDate,
          Notes: mboAppointmentNotes,
        };

        createAppointment(siteId, appointmentData, {fko: true})
          .then((response) => {
            resolve({
              playerId: player.playerId,
              confirmed: true,
              response: response,
            });
          })
          .catch((err) => {
            const [errorCode, resyncId] = [err.response?.data?.code, err.response?.data?.resyncTaskId];
            if (errorCode === 'ValidationFailed' && resyncId) {
              triggerBackendResync({resyncId});
            }

            const errorMsg =
              errorCode === 'ValidationFailed'
                ? 'The selected time is not available anymore. Please select a different time.'
                : 'Failed to make reservation';

            resolve({
              playerId: player.playerId,
              confirmed: false,
              error: errorMsg,
            });
          });
      })
    );
  }

  return Promise.all(promises);
};

export const createUser = async (email: string) => {
  const password = createRandomPassword();
  const createUserResult = await createUserWithEmailAndPassword(fireBaseAuth, email, password);

  logSignUpEvent('emailAndPassword', {
    userUid: createUserResult?.user?.uid,
    operationType: createUserResult?.operationType,
  });

  if (!fireBaseAuth.currentUser.emailVerified) {
    await sendEmailVerification(fireBaseAuth.currentUser);
  }

  return createUserResult;
};

export const fetchCurrentUserWithRetry = async (
  retryCount = 20
): Promise<
  ApiUser & {
    _pending?: any;
  }
> => {
  const rejectPromiseWithDelay = (error: string) =>
    new Promise((resolve, reject) => {
      setTimeout(() => reject(error), 1000);
    });

  let count = 0;
  let userProfile: any;

  while (count < retryCount) {
    try {
      userProfile = await getCurrentUser()
        .then(async (res) => {
          const user = res.data;
          const pending = user?._pending;
          if (!user || pending) {
            return rejectPromiseWithDelay('pending');
          } else {
            return user;
          }
        })
        .catch(() => {
          return rejectPromiseWithDelay('error');
        });
    } catch {
      //
    }

    if (userProfile && userProfile !== 'pending' && userProfile !== 'error') {
      count = retryCount;
    } else {
      count++;
    }
  }

  if (!userProfile) {
    void saveErrorDetails({
      key: 'fetchCurrentUserWithRetry',
      message: 'Failed to fetch current user with retry',
      description: '[fetchCurrentUserWithRetry] No user profile returned after max retries',
      severity: 'high',
      context: 'fko',
      accountId: getCurrentAccountId() ?? undefined,
      url: window.location.href,
    });
  }

  return userProfile;
};
