import {useSearchParams} from 'react-router-dom';
import {useGetTeamPublicInfo} from 'api/User/useGetTeamPublicInfo';
import {useValidateTokenAndGetUserInfo} from 'api/User/useValidateTokenAndGetUserInfo';
import Spinner from 'components/Loader/Spinner';
import {ContentRegistrationForm} from './ContentRegistrationForm';
import tocaNewLogo from '../../assets/img/my-toca-new-logo.svg';
import {TokenInfo, TeamInfo, OnboardingContent} from './types/';

const getOnboardingContent = (tokenInfo: TokenInfo, teamInfo: TeamInfo | null): OnboardingContent => {
  const defaultContent = {
    heading: 'Welcome to Onboarding',
    subtitle: 'Please sign up and register',
    logoUrl: '',
    registrationHeading: 'Registration',
  };

  if (!tokenInfo?.role?.[0]) {
    return defaultContent;
  }

  const roleContent: Record<string, Partial<OnboardingContent>> = {
    coach: {
      heading: 'Coach Onboarding',
      subtitle: 'Please sign up and register for your coaching sessions',
      registrationHeading: 'Coach Registration',
    },
    director: {
      heading: 'Director Onboarding',
      subtitle: 'Please sign up to oversee team management',
      registrationHeading: 'Director Registration',
    },
  };

  const roleSpecificContent = roleContent[tokenInfo.role[0].toLowerCase()] || {};

  return {
    heading: teamInfo?.teamName || roleSpecificContent.heading || defaultContent.heading,
    subtitle: roleSpecificContent.subtitle || defaultContent.subtitle,
    logoUrl: teamInfo?.logoUrl || defaultContent.logoUrl,
    registrationHeading: roleSpecificContent.registrationHeading || defaultContent.registrationHeading,
  };
};

const LoadingSpinner = () => (
  <div className="flex flex-col items-center justify-center min-h-screen">
    <img className="my-10" src={tocaNewLogo} height={12} width={80} alt="TOCA Logo" />
    <Spinner darkMode />
  </div>
);

const ErrorMessage = ({message}: {message: string}) => (
  <div className="flex flex-col justify-center items-center min-h-screen text-white text-xl">
    <img className="my-10" src={tocaNewLogo} height={12} width={80} alt="TOCA Logo" />
    <p>{message}</p>
  </div>
);

export const NewUserOnboarding = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? '';

  const {data: tokenInfo, isError: isTokenError, isFetching: isTokenFetching} = useValidateTokenAndGetUserInfo({token});

  const {
    data: teamInfo,
    isError: isTeamError,
    isFetching: isTeamFetching,
  } = useGetTeamPublicInfo({
    teamId: tokenInfo?.teamId,
  });

  if (!token) {
    return <ErrorMessage message="No token provided" />;
  }
  if (isTokenFetching) {
    return <LoadingSpinner />;
  }

  if (isTokenError || !tokenInfo) {
    return <ErrorMessage message={`Token is invalid. Can't onboard user.`} />;
  }

  if (isTeamFetching) {
    return <LoadingSpinner />;
  }

  if (isTeamError) {
    return <ErrorMessage message={`Unable to load team information.`} />;
  }

  const content = getOnboardingContent(tokenInfo, teamInfo || null);

  return <ContentRegistrationForm tokenInfo={tokenInfo} token={token} content={content} />;
};
