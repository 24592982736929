import {Outlet} from 'react-router-dom';
import {OnboardingContainer} from 'components/PageContainer/OnboardingContainer';

export const OnboardingLayout = () => {
  return (
    <OnboardingContainer>
      <Outlet />
    </OnboardingContainer>
  );
};
