import {ReactNode, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {TocaLocation} from 'constants/locations';

const activities = [
  {
    key: 'fkoSupported',
    title: 'Baseline Session - TOCA Training - for ages 7+',
    description: 'Schedule a FREE, no-commitment private soccer skills assessment.',
    route: 'fko',
  },
  {
    key: 'dbatSupported',
    title: 'D-BAT Player Evaluation - Baseball/Softball - for ages 7+',
    description: 'Schedule a FREE, no-commitment private D-BAT skills assessment.',
    route: 'dbat-fps',
  },
  {
    key: 'classesFreeTrialEnabled',
    title: 'Soccer Classes - for ages 1-13*',
    description: 'Schedule a FREE, no-commitment class for your child.',
    footnote: '* Class ages vary depending on center availability',
    route: 'ftc',
  },
  {
    key: 'pickupSoccerSupported',
    title: 'Adult Pickup Soccer - for ages 18+',
    description: 'Try out pickup soccer today.',
    route: 'ftp',
  },
  {
    key: 'leaguesSupported',
    title: 'Adult Soccer Leagues - for ages 18+',
    description: 'Try out one of our soccer leagues for free.',
    route: 'ftl',
  },
];

const ActivityLink = ({
  activity,
  location,
}: {
  activity: {
    title: string;
    route: string;
    key: string;
    description?: string | ReactNode;
    footnote?: string | ReactNode;
  };
  location: TocaLocation;
}) => {
  const routerLocation = useLocation();

  const handleClick = useCallback(() => {
    // Existing search params
    const params = new URLSearchParams(routerLocation.search);

    const updateParams = () => {
      // Remove existing locationId if present
      params.delete('locationId');
      // Set location and phone number params based on activity
      if (activity.key === 'pickupSoccerSupported' || activity.key === 'leaguesSupported') {
        if (location.hubspotName) {
          params.set('locations__drop_down_', location.hubspotName);
        }
      } else {
        params.set('locationId', location.siteId);
      }
    };

    updateParams();
    window.location.href = `/${activity.route}?${params.toString()}`;
  }, [location, routerLocation]);

  return (
    <div
      className="cursor-pointer mt-2 text-center p-3 border rounded border-primary hover:bg-primary hover:text-white group"
      onClick={handleClick}
    >
      <div className="text-lg font-semibold text-primary group-hover:text-white">{activity.title}</div>
      {!!activity.description && <div className="text-sm mt-1">{activity.description}</div>}
      {!!activity.footnote && <div className="text-xs mt-1">{activity.footnote}</div>}
    </div>
  );
};

export const ActivityLinks = ({location}: {location: TocaLocation}) => {
  // Check if any activity is enabled
  const isAnyActivityEnabled = activities.some((activity) => location[activity.key as keyof TocaLocation]);

  return (
    <div className="flex flex-col items-center">
      {isAnyActivityEnabled ? (
        <>
          <p className="text-base text-center mt-6 mb-4 w-full">
            Great choice! {location.name} offers the following activities. Click on any activity to sign up.
          </p>
          <div className="flex flex-col w-full">
            {activities.map(
              (activity) =>
                location[activity.key as keyof TocaLocation] && (
                  <ActivityLink key={activity.title} activity={activity} location={location} />
                )
            )}
          </div>
        </>
      ) : (
        <p className="text-lg text-primary font-semibold cursor-pointer mt-6">
          No activities available for this location.
        </p>
      )}
    </div>
  );
};
