import {Accordion} from 'components/Accordion/Accordion';
import {memo} from 'react';

export interface FAQItem {
  heading: string;
  content: string | JSX.Element;
}

const FAQSection = memo(({faqs}: {faqs: FAQItem[]}) => {
  if (!faqs || faqs.length === 0) {
    return null;
  }
  return (
    <div className="p-16 pl-36 pr-36 bg-[#f7f8fc] mt-8">
      <p className="text-primary text-2xl font-bold text-center mb-8">Frequently Asked Questions</p>
      <Accordion cards={faqs} />
    </div>
  );
});

FAQSection.displayName = 'FAQSection';
export default FAQSection;
