import {useSearchParams} from 'react-router-dom';
import {ChangeEventHandler, useCallback, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {TocaLogo} from 'components/Forms/FkoLogos/TocaLogo';
import {SelectOptions, StandardSelect} from 'components/Select/StandardSelect/StandardSelect';
import {TocaLocation, tocaLocations, tocaLocationsWithoutWorkingMboSiteId} from 'constants/locations';
import {ActivityLinks} from './ActivityLinks';
import {isDevelopmentEnvironment} from '../../common/isTestEnvironment';

const createLocationOptions = (locations: TocaLocation[]) => [
  {value: '', displayValue: 'Select location', disabled: true},
  ...locations.map((location) => ({
    value: location.siteId,
    displayValue: location.name,
  })),
];

const locations = [...tocaLocations, ...tocaLocationsWithoutWorkingMboSiteId].filter(
  (loc) => (!isDevelopmentEnvironment && !loc.devSite) || (isDevelopmentEnvironment && loc.devSite)
);

const getLocationByHubspotName = (hubspotName: string): TocaLocation | undefined => {
  return locations.find((location) => location.hubspotName === hubspotName);
};

const locationOptions = createLocationOptions(locations);

const ReferralLanding = () => {
  const [selectedLocation, setSelectedLocation] = useState<TocaLocation | null>();
  const onSelectLocation: ChangeEventHandler<SelectOptions> = useCallback((e) => {
    setSelectedLocation(locations.find((location) => location.siteId === e.currentTarget.value));
  }, []);
  const [searchParams] = useSearchParams();

  // TODO: Improve the reset of selected location after browser back navigation
  useEffect(() => {
    // Getting location info from search params and setting default location on mount
    const locationHubspotName = searchParams.get('locations__drop_down_');
    const locationId = searchParams.get('locationId');
    const location = locationHubspotName
      ? getLocationByHubspotName(locationHubspotName)
      : locationId
      ? locations.find((location) => location.siteId === locationId)
      : null;
    setSelectedLocation(location);
  }, [searchParams]);

  return (
    <div className="flex flex-col items-center exceed-classic-padding pl-6 pr-6">
      <Helmet>
        <title>Referral</title>
      </Helmet>
      <TocaLogo />
      <h1 className="text-[26px] text-center mt-2 mb-4">Welcome to TOCA!</h1>
      {!selectedLocation && (
        <div className="font-light text-base text-center mb-6">
          <p className="font-semibold">Select your center to sign up for a FREE experience.</p>
          <p className="mt-6">
            When you join, you&apos;ll also receive{' '}
            <span className="font-semibold">15% off your first month of membership</span> so you can experience all
            TOCA&apos;s benefits firsthand.
          </p>
        </div>
      )}
      <StandardSelect
        id="location-select"
        options={locationOptions}
        onChange={onSelectLocation}
        variant="normal"
        value={selectedLocation?.siteId || locationOptions[0].value}
      />
      {selectedLocation && <ActivityLinks location={selectedLocation} />}
    </div>
  );
};

ReferralLanding.displayName = 'ReferralLanding';

export default ReferralLanding;
