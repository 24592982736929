import {SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ErrorMessage} from 'components/Forms/ErrorMessage/ErrorMessage';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {FormButton} from 'components/Forms/FormButton/FormButton';
import Spinner from 'components/Loader/Spinner';

export interface NewUserOnboardingRegistrationFormSubmission {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

interface NewUserOnboardingRegistrationFormSubmissionProps {
  isLoading: boolean;
  onSubmitHandler: SubmitHandler<NewUserOnboardingRegistrationFormSubmission>;
  errorMessage: string;
  defaultEmail: string;
}

export const NewUserOnboardingRegistrationForm = ({
  isLoading,
  onSubmitHandler,
  errorMessage,
  defaultEmail,
}: NewUserOnboardingRegistrationFormSubmissionProps) => {
  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required').trim('Name cannot include leading or trailing spaces'),
    lastName: yup.string().required('Required').trim('Name cannot include leading or trailing spaces'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<NewUserOnboardingRegistrationFormSubmission>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="border border-grey rounded-md mb-3 p-2 pt-4">
        <div>
          <p className="text-sm text- mb-4 ml-2">Your email: {defaultEmail}</p>
        </div>

        <StandardInput
          spellCheck="false"
          {...register('firstName')}
          type="text"
          id="firstName"
          placeholder="First Name"
          hasError={!!errors.firstName?.message}
        />
        <ErrorMessage text={errors.firstName?.message ?? '\u00A0'} />

        <StandardInput
          spellCheck="false"
          {...register('lastName')}
          type="text"
          id="lastName"
          placeholder="Last Name"
          hasError={!!errors.lastName?.message}
        />
        <ErrorMessage text={errors.lastName?.message ?? '\u00A0'} />

        <StandardInput
          spellCheck="false"
          {...register('password')}
          type="password"
          id="password"
          placeholder="password"
        />
        <ErrorMessage text={errors.password?.message ? errors.password.message : '\u00A0'} />
      </div>
      <ErrorMessage text={errorMessage ? errorMessage : '\u00A0'} />
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-[48px] rounded-[12px] bg-primary">
          <Spinner darkMode />
        </div>
      ) : (
        <FormButton type="submit" isPrimary hasError={Object.keys(errors).length !== 0}>
          REGISTER
        </FormButton>
      )}
    </form>
  );
};

NewUserOnboardingRegistrationForm.displayName = 'NewUserOnboardingRegistrationForm';
