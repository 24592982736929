import {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import Spinner from 'components/Loader/Spinner';
import {ReactComponent as InviteIcon} from '../../../assets/img/icons/invite-icon.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/img/icons/delete.svg';
import AvatarProfile from '../../../assets/img/avatar-profile.png';
import {useGetTeamCoaches} from 'api/Director/useGetTeamCoaches';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {useProfiles} from 'api/User/useProfiles';
import {Team} from 'user/user.interface';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {deleteCoachFromTeam} from 'api/api';
import {AddCoach} from '../AddCoach/AddCoach';
import {getUserDisplayName} from 'utils/utils';

const getCoachDisplayRole = (coachId: string, team: Team) => {
  const headCoachIds = team?.headCoaches || [];
  if (headCoachIds.includes(coachId)) {
    return 'HEAD COACH';
  }
  return 'ASSISTANT COACH';
};

export const Coaches = () => {
  const {teamId} = useParams();
  const queryClient = useQueryClient();
  const {currentProfile} = useProfiles();
  const {data: directorInfo} = useGetDirectorInfo(currentProfile?._id);
  const {data: coaches = [], isLoading} = useGetTeamCoaches(teamId as string);
  const [showAddCoachModal, setShowAddCoachModal] = useState(false);
  const [showDeleteCoachModal, setShowDeleteCoachModal] = useState<boolean>(false);
  const [coachIdToBeDeleted, setCoachIdToBeDeleted] = useState<string>('');

  const team = useMemo(() => directorInfo?.teams.find((team) => team._id === teamId), [teamId, directorInfo?.teams]);

  const deleteCoachMutation = useMutation({
    mutationFn: deleteCoachFromTeam,
    onSuccess: () => {
      queryClient.invalidateQueries(['team-coaches', teamId]);
      queryClient.invalidateQueries(['director-info']);
      setCoachIdToBeDeleted('');
      setShowDeleteCoachModal(false);
      toast.success('Successfully deleted coach.');
    },
    onError: () => {
      toast.error('Failed to delete coach.');
    },
  });

  const onClickInviteHandler = () => {
    setShowAddCoachModal(true);
  };

  const onClickDeleteCoachHandler = (coachId: string) => {
    setShowDeleteCoachModal(true);
    setCoachIdToBeDeleted(coachId);
  };

  const onClickDeleteCoachConfirmationHandler = () => {
    const coachType = team?.headCoaches.includes(coachIdToBeDeleted) ? 'head-coach' : 'assistant-coach';
    deleteCoachMutation.mutate({teamId: teamId as string, coachId: coachIdToBeDeleted, coachType});
  };

  if (isLoading) {
    return (
      <div className="w-full h-full flex flex-1 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col w-full justify-between">
      <AddCoach
        showModal={showAddCoachModal}
        cancelModal={() => setShowAddCoachModal(false)}
        teamId={teamId as string}
        siteId={team?.siteId as string}
      />

      <ConfirmationModal
        isOpen={showDeleteCoachModal}
        message="Are you sure you want to delete coach?"
        onConfirm={onClickDeleteCoachConfirmationHandler}
        onCancel={() => {
          setShowDeleteCoachModal(false);
          setCoachIdToBeDeleted('');
        }}
      />

      <div className="flex flex-col items-center relative">
        <div className="pt-8">
          <div className="text-5xl text-center text-primary font-medium">COACHES</div>
        </div>

        <div className="w-full mt-4">
          <div className="flex flex-wrap justify-center lg:justify-start mt-4">
            {!coaches?.length ? (
              <div className="text-base">No coaches found</div>
            ) : (
              coaches?.map((coach) => {
                const coachName = getUserDisplayName(coach.firstName, coach.lastName);
                return (
                  <div
                    data-test-id={coachName}
                    key={coach._id}
                    className="h-36 w-[102px] pl-1 pr-1 mr-2 mt-1 mb-1 rounded-md border-grey border flex flex-col items-center shadow-lg relative"
                  >
                    <DeleteIcon
                      data-test-id="delete-coach"
                      className="cursor-pointer absolute top-2 right-1 text-grey w-3 h-3"
                      onClick={() => onClickDeleteCoachHandler(coach._id)}
                    />
                    <img
                      src={coach.avatarUrl || AvatarProfile}
                      className="rounded-full mt-4 min-h-16 min-w-16 h-16 w-16"
                    />
                    <div className="text-md mt-5 line-clamp-2 w-full text-center" title={coachName}>
                      {coachName}
                    </div>
                    <div className="text-md mt-1 mb-2 text-primary ">
                      {getCoachDisplayRole(coach?._id, team as Team)}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <div className="fixed md:absolute right-2 md:right-6 bottom-24 cursor-pointer">
        <InviteIcon data-test-id="add-coach" onClick={onClickInviteHandler} />
      </div>
    </div>
  );
};
