import {useMutation, useQueryClient} from '@tanstack/react-query';
import {updateOnboardingProfileAPI, OnboardingProfileUpdate} from '../api';
import {useCallback} from 'react';
import {User} from '../../user/user.interface';

export const useUpdateOnboardingProfile = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<User, unknown, OnboardingProfileUpdate>({
    mutationFn: (userInfo: OnboardingProfileUpdate) => updateOnboardingProfileAPI(userInfo).then((res) => res.data),
    onError: (error) => {
      console.error('error updating onboarding profile', error);
    },
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['user']}),
  });

  const updateOnboardingProfile = useCallback(
    async (userInfo: OnboardingProfileUpdate) => {
      return mutation.mutate(userInfo);
    },
    [mutation]
  );

  return {updateOnboardingProfile};
};
