import {useQuery} from '@tanstack/react-query';
import {validateTokenAndGetUserInfo} from 'api/api';
import {TokenInfo} from 'pages/NewUserOnboarding/types';

export const useValidateTokenAndGetUserInfo = ({token}: {token: string}) => {
  return useQuery<TokenInfo>({
    queryKey: ['validate-token', token],
    queryFn: () => validateTokenAndGetUserInfo(token),
    enabled: !!token,
  });
};
