import {memo} from 'react';
import styles from './StandardSelect.module.scss';

export type SelectOptions = {
  value: string | number;
  displayValue?: string;
  disabled?: boolean;
};
export interface StandardSelectProps extends React.SelectHTMLAttributes<SelectOptions> {
  id: string;
  options: SelectOptions[];
  variant?: 'primary' | 'normal';
}

export const StandardSelect = (props: StandardSelectProps) => {
  const {id, options, variant = 'primary', ...rest} = props;

  return (
    <div className={`${styles.container} ${variant === 'normal' ? styles.normal : ''}`}>
      <select id={id} {...rest}>
        {options.map((option, i) => (
          <option key={`${id}_sel_opt_${i}`} value={option.value} disabled={option.disabled}>
            {option.displayValue ?? option.value}
          </option>
        ))}
      </select>
    </div>
  );
};
StandardSelect.displayName = 'StandardSelect';
