import {useState, memo, useCallback} from 'react';
import styles from './Accordion.module.scss';
import {ReactComponent as ChevronDown} from '../../assets/img/icons/new-chevron-down.svg';

export type AccordionCard = {
  heading: string;
  content: string | JSX.Element;
};

type AccordionCardProps = {
  card: AccordionCard;
  isOpen?: boolean;
};

export type AccordionProps = {
  cards: AccordionCard[];
};

export const Accordion = memo(({cards}: AccordionProps) => {
  return (
    <ul className={styles.accordionContainer}>
      {cards.map((card, index) => (
        <AccordionCard card={card} key={index} />
      ))}
    </ul>
  );
});
Accordion.displayName = 'Accordion';

const AccordionCard = memo((props: AccordionCardProps) => {
  const {
    card: {heading, content},
    isOpen = false,
    ...rest
  } = props;
  const [active, setActive] = useState(isOpen);

  const toggleActive = useCallback(() => {
    setActive((prev) => !prev);
  }, []);

  return (
    <li className={`${styles.cardContainer} ${active ? styles.active : ''}`} {...rest}>
      <button className={styles.heading} onClick={toggleActive}>
        <h4>{heading}</h4>
        <div className={styles.icon}>
          <ChevronDown />
        </div>
      </button>
      <div className={styles.contentContainer}>
        <div className={styles.content}>{content}</div>
      </div>
    </li>
  );
});
AccordionCard.displayName = 'AccordionCard';
