import {useGetSport} from 'common/useGetSport';
import {useAppSelector} from 'redux/reduxUtils';
import FAQSection, {FAQItem} from 'components/FAQSection/FAQSection';
import {FKOFAQ} from './FKOFAQ';
import {DBATFAQ} from './DBATFAQ';

const faqMapping: Record<string, FAQItem[]> = {
  toca: FKOFAQ,
  dbat: DBATFAQ,
};

const getFaqsBySportType = (sport: string): FAQItem[] => {
  return faqMapping[sport] ?? [];
};

export const FKOFAQSection = () => {
  const {sport} = useGetSport();
  const {isInRegistrationPage} = useAppSelector((state) => state.fkoFormDataReducer);

  if (!isInRegistrationPage) {
    return null;
  }

  const faqs = getFaqsBySportType(sport);

  return (
    <div className="hidden lg:block">
      <FAQSection faqs={faqs} />
    </div>
  );
};
