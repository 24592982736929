import {FAQItem} from 'components/FAQSection/FAQSection';
import {scrollToTop} from 'components/FAQSection/utils';

export const FKOFAQ: FAQItem[] = [
  {
    heading: 'Why TOCA?',
    content: (
      <div className="text-sm">
        <div>TOCA&apos;s individualized training offers the fastest player improvement.</div>
        <div className="mt-3">
          Our technology enhanced training provides 10 matches worth of reps in each session. Watch your player&apos;s
          skills and confidence skyrocket.
        </div>
        <div className="mt-3"> TOCA Training is endorsed by MLS and celebrated by parents.</div>
      </div>
    ),
  },
  {
    heading: 'What does a free Baseline Session look like?',
    content: (
      <div className="text-sm">
        <div>
          When you arrive at TOCA, you&apos;ll be treated like a pro. You&apos;ll have your very own indoor training
          space equipped with our training technology and a certified TOCA trainer to guide you through your session.
        </div>
        <div className="mt-3">
          Your trainer will get to know you as a player – your goals, aspirations and soccer history. Then, we&apos;ll
          hop in the studio and start training!
        </div>
        <div className="mt-3">
          We use the Baseline Session to get to know your strengths and weaknesses so we can map out a path to help you
          improve as quickly as possible.
        </div>
      </div>
    ),
  },
  {
    heading: 'How much is Individual Training?',
    content: (
      <div className="text-sm">
        <div> At most centers, Individual TOCA Training Memberships start at $199 per month.</div>
        <div className="mt-3">
          New players are invited to start with a FREE, no-commitment, private Baseline skills assessment.{' '}
          <a className="text-fko-link-color" href="#" onClick={scrollToTop}>
            Sign up today
          </a>
        </div>
      </div>
    ),
  },
  {
    heading: 'How are a Baseline session and Individual Training related?',
    content: (
      <div className="text-sm">
        Our Baseline Sessions are used for your trainer to get to know you as a player. We use the Baseline Session to
        get to know your strengths and weaknesses so we can map out a training path to help you reach your goals!
        Baseline Sessions are a cornerstone of your Individual Training plan as they ensure your plan is tailored to
        your skill level and aspirations.
      </div>
    ),
  },
  {
    heading: 'Who is TOCA Individual Training best suited for?',
    content: (
      <div className="text-sm">
        <div>
          {' '}
          TOCA&apos;s best-in-class, tech-enhanced Individual Training is designed for players (ages 7 through adult)
          who play at a competitive level. For kids, this is usually club/select team players or those who aspire to
          compete at that level.
        </div>
        <div className="mt-3">
          Participants range from kids playing on competitive teams to future & current professional players, many of
          whom continue to train at TOCA to sharpen their skills.
        </div>
        <div className="mt-3">
          After your initial free Baseline Session, where we get to know your strengths and opportunities for
          improvement, we&rsquo;ll map out an individualized training path aimed at fast improvement.
        </div>
        <div className="mt-3">
          For kids (ages 1-13) who are more comfortable at a rec team level and other casual players, TOCA&apos;s Soccer
          Classes are likely a better fit. Learn more about{' '}
          <a href="https://toca-website.webflow.io/classes">TOCA Soccer Classes.</a>
        </div>
      </div>
    ),
  },
  {
    heading: 'What is the difference between a Baseline Session and a Class Trial?',
    content: (
      <div className="text-sm">
        <div>
          A Baseline Session is the starting point for Individual Training geared towards competitive players, while a
          Class Trial is the starting point for group lessons that are geared toward beginners and recreational players.
          Additional details about each:
        </div>
        <div className="font-semibold mt-3">Baseline Session</div>
        <div>
          Our Baseline Session is the first step towards starting your TOCA Training Journey and is an individual
          experience to determine your player&apos;s strengths and opportunities. TOCA Training and Baseline Sessions
          are best suited for players (ages 7 through adult) who play at a competitive level. For kids, this is usually
          club/select team players or those who aspire to compete at that level.
        </div>
        <div className="font-semibold mt-3">Class Trial</div>
        <div>
          Our Class Trial is the first step towards starting TOCA Soccer Classes and is a group experience. TOCA Soccer
          Classes are best suited for players age 1-13 who are just getting started, more comfortable at a rec team
          level, or are casual players.
        </div>
      </div>
    ),
  },
];
