import {Helmet} from 'react-helmet';
import {useSearchParams} from 'react-router-dom';
import {useGetTeamPublicInfo} from 'api/User/useGetTeamPublicInfo';
import {roleType} from 'constants/constants';
import {ReactComponent as MyTocaNewLogo} from '../../assets/img/my-toca-new-logo.svg';
import LoaderFullPage from 'pages/LoaderFullPage/LoaderFullPage';
import {playerAssessmentSessionTypeName} from '../../components/FKO/PlayerAssessmentSportWrapper';
import styles from '../../components/Connects/ConnectsComponents.module.scss';

const isValidRoleForTeamOnboarding = (role: string) => [roleType.player].includes(role);

export const TeamOnboarding = () => {
  const [searchParams] = useSearchParams();
  const [teamId, role, location] = [
    searchParams.get('teamId') ?? '',
    searchParams.get('role') ?? '',
    searchParams.get('locationId') ?? '',
  ];
  const {data: teamPublicInfo, isLoading, error} = useGetTeamPublicInfo({teamId});
  const linkHref = `/player-assessment?teamId=${teamId}&role=${role}&locationId=${location}&sessionTypeNames=${playerAssessmentSessionTypeName}`;

  // TODO Using navigate somehow causes an incorrect context to be set on the subsequent route
  // const onClickGetStartedHandler = () => {
  //   navigate(
  //     `/player-assessment?teamId=${teamId}&role=${role}&locationId=${location}&sessionTypeNames=${playerAssessmentSessionTypeName}`
  //   );
  // };

  return (
    <>
      <Helmet>
        <title>MyTOCA | Team Onboarding</title>
      </Helmet>
      {isLoading ? (
        <LoaderFullPage />
      ) : !teamId ||
        !location ||
        teamPublicInfo?.siteId !== location ||
        !role ||
        error ||
        !isValidRoleForTeamOnboarding(role) ? (
        <div className="text-white text-sm p-2">Invalid invite link</div>
      ) : (
        <div className="flex flex-col items-center w-full min-h-screen justify-between p-4 pt-16 pb-16">
          <MyTocaNewLogo />
          <div className="flex flex-col items-center justify-evenly">
            <div className="text-white text-base font-semibold mb-4">WELCOME TO YOUR TEAM</div>
            <div className="flex flex-col items-center bg-white rounded-md pt-4 pb-4 w-[200px]">
              <img className="w-32 h-32 object-cover rounded-md" src={teamPublicInfo?.logoUrl} />
              <div className="text-center text-lg m-4 mb-0">{teamPublicInfo?.teamName}</div>
            </div>
          </div>
          {/*<Button text="GET STARTED" color="primary" variant="standard" onClick={onClickGetStartedHandler} />*/}
          <a href={linkHref} className={`${styles.button} ${styles.primary} ${styles.standard}`}>
            GET STARTED
          </a>
        </div>
      )}
    </>
  );
};
