import {FAQItem} from 'components/FAQSection/FAQSection';
import {scrollToTop} from 'components/FAQSection/utils';

export const DBATFAQ: FAQItem[] = [
  {
    heading: 'Why D-BAT?',
    content: (
      <div className="text-sm">
        <div>
          D-BAT is the premier choice of baseball and softball training centers for athletes, parents and coaches alike.
        </div>

        <div className="mt-3">
          D-BAT is a membership based facility that includes individualized training with expert instructors, camps and
          clinics to hone in on specific skills, as well as cage rentals and pitching machines to get extra reps in
          whenever you want.
        </div>
      </div>
    ),
  },
  {
    heading: 'How Much Is It?',
    content: (
      <div className="text-sm">
        <div>
          At most centers, D-BAT Lessons start at $70 per hour based on membership tier, duration, and frequency.
        </div>

        <div className="mt-3">
          New players are invited to start with a FREE, no-commitment, Private Evaluation Lesson.{' '}
          <a className="text-fko-link-color" href="#" onClick={scrollToTop}>
            Sign up today.
          </a>
        </div>
      </div>
    ),
  },
  {
    heading: 'What Does a Free Private Evaluation Lesson Look Like?',
    content: (
      <div className="text-sm">
        <div>
          When you arrive at D-BAT, you&apos;ll be treated like a pro. You&apos;ll have your very own indoor training
          space and a highly skilled D-BAT instructor to guide you through your lesson.
        </div>
        <div className="mt-3">
          Your Instructor will get to know you as a player – your goals, aspirations and baseball or softball history.
          Then, we&apos;ll hop in the cage and start training!
        </div>
        <div className="mt-3">
          We use the Private Evaluation Lesson to get to know your strengths and weaknesses so we can map out a path to
          help you improve as quickly as possible.
        </div>
      </div>
    ),
  },
];
